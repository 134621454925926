<template>
  <div>
    <p class="display-1 mt-2 mb-5 text-uppercase">CONTAINER TRACKING</p>
    <ContainerTrackingSearch />
  </div>
</template>

<script>
import ContainerTrackingSearch from '@/components/shared/local/vsl/ContainerTrackingSearch';
export default {
  components: { ContainerTrackingSearch },
  data: () => ({}),
  computed: {},
  destroyed() {},
  methods: {},
};
</script>

<style></style>
